/*
LocalStorage keys
*/
export const storage = {
    authentication: 'Authorization_' + btoa(window.location.host),
    sidebar: '__sidebar',
    cookie_settings: '__cs_upd_'
}
export const defaultSidebar = ''

//cookie disclaimer
export const defaultCookieSettings = {
    is_default: true,
    cookies: {
        necessary: true,
        statistics: false,
        marketing: false,
    }
}

// Toaster
export const defaultToaster = {
    toasterType: "OK",
    title: '',
    message: '',
    counter: 0
}

//fetch state
export const initialFetchState = {
    data: {},
    isFetching: false,
    shouldReload: false,
    errors: {},
    message: ''
}

//fetch sequence state
export const initialSequenceFetchState = {
    data: [],
    isFetching: false,
    errors: {},
    message: ''
}

export const initialFrontendFetchState = {
    'landing': {
        data: [],
        isFetching: false
    },
    'faqs': {
        data: [],
        isFetching: false
    },
    'documents': {},
    'pages': {},
}

//enum fetch state
export const initialEnumFetchState = {
    'filter-boolean': {
        data: [
            {value: 1, label: 'yes'},
            {value: 0, label: 'no'},
            {value: null, label: 'any'},
        ],
        isFetching: false,
        shouldReload: false
    },
    'service-icons': {
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => ({value: i, label: i + '.svg'})),
        isFetching: false,
        shouldReload: false
    },
    'pdf-actions': {
        data: {
            download: {value: 'download', label: 'download'},
            show: {value: 'show', label: 'show'},
        },
        isFetching: false,
        shouldReload: false
    },
    'gallery-types': {
        data: {
            about: {value: 'about', label: 'about'},
            references: {value: 'references', label: 'references'},
            default: {value: 'default', label: 'default'},
        },
        isFetching: false,
        shouldReload: false
    }
}

//enum fetch Item state
export const initialEnumItemFetchState = {
    data: [],
    isFetching: false,
    shouldReload: false
}

//user state
export const initialUserFetchState = {
    isAuthenticated: false,
    isFetching: false,
    user: {},
}

