/*TOASTER*/
export const SET_TOASTER = "SET_TOASTER"

/*COOKIE*/
export const SET_COOKIE_SETTINGS = "SET_COOKIE_SETTINGS"

/*SIDEBAR*/
export const CHANGE_SIDEBAR = "CHANGE_SIDEBAR"

/* FILTERS */
export const SET_FILTER_DATA = "SET_FILTER_DATA"
export const TOGGLE_FILTER = "TOGGLE_FILTER"

/* AUTH */
export const REQUEST_CURRENT_USER = "REQUEST_CURRENT_USER"
export const RECEIVE_CURRENT_USER = "RECEIVE_CURRENT_USER"
export const ERROR_CURRENT_USER = "ERROR_CURRENT_USER"

/* INFO */
export const REQUEST_INFO = "REQUEST_INFO"
export const RECEIVE_INFO = "RECEIVE_INFO"
export const ERROR_INFO = "ERROR_INFO"

/* FRONTEND */
export const REQUEST_FRONTEND = "REQUEST_FRONTEND"
export const RECEIVE_FRONTEND = "RECEIVE_FRONTEND"
export const ERROR_FRONTEND = "ERROR_FRONTEND"

/* STRINGS */
export const REQUEST_STRINGS = "REQUEST_STRINGS"
export const RECEIVE_STRINGS = "RECEIVE_STRINGS"
export const UPDATE_STRINGS = "UPDATE_STRINGS"
export const ERROR_STRINGS = "ERROR_STRINGS"
export const TOGGLE_DEV_STRINGS = "TOGGLE_DEV_STRINGS"

/* ADMIN STRINGS */
export const REQUEST_ADMIN_STRINGS = "REQUEST_ADMIN_STRINGS"
export const RECEIVE_ADMIN_STRINGS = "RECEIVE_ADMIN_STRINGS"
export const UPDATE_ADMIN_STRINGS = "UPDATE_ADMIN_STRINGS"
export const ERROR_ADMIN_STRINGS = "ERROR_ADMIN_ROLES"

/* ADMINS */
export const REQUEST_ADMINISTRATORS = "REQUEST_ADMINISTRATORS"
export const RECEIVE_ADMINISTRATORS = "RECEIVE_ADMINISTRATORS"
export const UPDATE_ADMINISTRATORS = "UPDATE_ADMINISTRATORS"
export const ERROR_ADMINISTRATORS = "ERROR_ADMINISTRATORS"

/* ROLES */
export const REQUEST_ROLES = "REQUEST_ROLES"
export const RECEIVE_ROLES = "RECEIVE_ROLES"
export const UPDATE_ROLES = "UPDATE_ROLES"
export const ERROR_ROLES = "ERROR_ROLES"

/* PERMISSIONS */
export const REQUEST_PERMISSIONS = "REQUEST_PERMISSIONS"
export const RECEIVE_PERMISSIONS = "RECEIVE_PERMISSIONS"
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS"
export const ERROR_PERMISSIONS = "ERROR_PERMISSIONS"

/* FAQ */
export const REQUEST_FAQS = "REQUEST_FAQS"
export const RECEIVE_FAQS = "RECEIVE_FAQS"
export const UPDATE_FAQS = "UPDATE_FAQS"
export const ERROR_FAQS = "ERROR_FAQS"

/* PARTNERS */
export const REQUEST_PARTNERS = "REQUEST_PARTNERS"
export const RECEIVE_PARTNERS = "RECEIVE_PARTNERS"
export const UPDATE_PARTNERS = "UPDATE_PARTNERS"
export const ERROR_PARTNERS = "ERROR_PARTNERS"

/* SERVICES */
export const REQUEST_SERVICES = "REQUEST_SERVICES"
export const RECEIVE_SERVICES = "RECEIVE_SERVICES"
export const UPDATE_SERVICES = "UPDATE_SERVICES"
export const ERROR_SERVICES = "ERROR_SERVICES"

/* HEADERCAROUSEL */
export const REQUEST_HEADER_CAROUSEL = "REQUEST_HEADER_CAROUSEL"
export const RECEIVE_HEADER_CAROUSEL = "RECEIVE_HEADER_CAROUSEL"
export const UPDATE_HEADER_CAROUSEL = "UPDATE_HEADER_CAROUSEL"
export const ERROR_HEADER_CAROUSEL = "ERROR_HEADER_CAROUSEL"

/* REFERENCES */
export const REQUEST_REFERENCES = "REQUEST_REFERENCES"
export const RECEIVE_REFERENCES = "RECEIVE_REFERENCES"
export const UPDATE_REFERENCES = "UPDATE_REFERENCES"
export const ERROR_REFERENCES = "ERROR_REFERENCES"

/* DOCUMENTS */
export const REQUEST_DOCUMENTS = "REQUEST_DOCUMENTS"
export const RECEIVE_DOCUMENTS = "RECEIVE_DOCUMENTS"
export const UPDATE_DOCUMENTS = "UPDATE_DOCUMENTS"
export const ERROR_DOCUMENTS = "ERROR_DOCUMENTS"

/* PAGES */
export const REQUEST_PAGES = "REQUEST_PAGES"
export const RECEIVE_PAGES = "RECEIVE_PAGES"
export const UPDATE_PAGES = "UPDATE_PAGES"
export const ERROR_PAGES = "ERROR_PAGES"

/* PDF-DOCUMENTS */
export const REQUEST_PDF_DOCUMENTS = "REQUEST_PDF_DOCUMENTS"
export const RECEIVE_PDF_DOCUMENTS = "RECEIVE_PDF_DOCUMENTS"
export const UPDATE_PDF_DOCUMENTS = "UPDATE_PDF_DOCUMENTS"
export const ERROR_PDF_DOCUMENTS = "ERROR_PDF_DOCUMENTS"

/* GALLERY */
export const REQUEST_GALLERY = "REQUEST_GALLERY"
export const RECEIVE_GALLERY = "RECEIVE_GALLERY"
export const UPDATE_GALLERY = "UPDATE_GALLERY"
export const ERROR_GALLERY = "ERROR_GALLERY"

/* ENUMS */
export const REQUEST_ENUMS = "REQUEST_ENUMS"
export const RECEIVE_ENUMS = "RECEIVE_ENUMS"
export const RELOAD_ENUMS = "RELOAD_ENUMS"
export const ERROR_ENUMS = "ERROR_ENUMS"

/* ITEM */
export const REQUEST_ITEM = "REQUEST_ITEM"
export const RECEIVE_ITEM = "RECEIVE_ITEM"
export const UPDATE_ITEM = "UPDATE_ITEM"
export const ERROR_ITEM = "ERROR_ITEM"

/* SEQUENCE */
export const REQUEST_SEQUENCE = "REQUEST_SEQUENCE"
export const RECEIVE_SEQUENCE = "RECEIVE_SEQUENCE"
export const ERROR_SEQUENCE = "ERROR_SEQUENCE"