import {
    REQUEST_SERVICES,
    RECEIVE_SERVICES,
    UPDATE_SERVICES,
    ERROR_SERVICES
} from '../types'
import {initialFetchState} from '../../constants/defaultValues'

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_SERVICES:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_SERVICES:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                errors: {},
                message: '',
            }
        case UPDATE_SERVICES:
            const data = state.data
            let index = null
            switch(action.updateType) {
                case 'create':
                    data.items.unshift(action.payload)
                    break
                case 'update':
                    if(data.items){
                        index = data.items.findIndex(item => item.id == action.id)
                        data.items[index] = action.payload
                    }
                    break
                case 'delete':
                    index = data.items.findIndex(item => item.id == action.id)
                    data.items.splice(index, 1)
                    break
            }
            return {
                ...state,
                isFetching: false,
                data,
                errors: {}
            }
        case ERROR_SERVICES:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: action.errors.server || '',
            }
        default:
            return state
    }
}