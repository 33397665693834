import { combineReducers } from 'redux'
import toaster from './toaster/reducer'
import auth from './auth/reducer'
import info from './info/reducer'
import strings from './strings/reducer'
import item from './item/reducer'
import sequence from './sequence/reducer'
import administrators from './administrators/reducer'
import roles from './roles/reducer'
import permissions from './permissions/reducer'
import adminStrings from './adminStrings/reducer'
import filters from './filters/reducer'
import {sidebar, locale, cookieSettings} from './settings/reducer'
import faqs from './faqs/reducer'
import services from './services/reducer'
import references from './references/reducer'
import headerCarousel from './headerCarousel/reducer'
import documents from './documents/reducer'
import pages from './pages/reducer'
import pdfDocuments from './pdfDocuments/reducer'
import partners from './partners/reducer'
import gallery from './gallery/reducer'

import enums from './enums/reducer'
import frontend from './frontend/reducer'

export default combineReducers({
  toaster,
  auth,
  info,
  strings,
  item,
  sequence,
  administrators,
  roles,
  permissions,
  enums,
  adminStrings,
  filters,
  sidebar,
  locale,
  cookieSettings,
  faqs,
  partners,
  services,
  references,
  headerCarousel,
  documents,
  pages,
  gallery,
  pdfDocuments,
  frontend,
})